import React from "react"
import { graphql } from "gatsby"
import styled, { withTheme } from "styled-components"
import Seo from "../components/molecules/Seo"
import Pagination from "../components/organisms/Pagination"
import Container from "../components/atoms/Container"
import ContentHolder from "../components/organisms/ContentHolder"
import JournalCard from "../components/molecules/JournalCard"
import Splash from "../components/organisms/Splash"
import ArrowText from "../components/atoms/ArrowText"
import CWReview from "../components/molecules/CWReview"
import thumb from "../../static/images/cw_home.png"

const Holder = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0 0 6rem 0;
`

function JournalList({ theme, data, pageContext }) {
  return (
    <>
      <Seo
        title="Journal | Craig Walker"
        thumb={thumb}
        description="Craig Walker designs and researches for the world’s leading organisations. These are some thoughts we’ve shared."
      />
      <Splash
        bg={theme.colours.green}
        text={
          <>
            <b>We design</b> <br />
            to create impact.
          </>
        }
      >
        <ArrowText>
          <p>
            Some thoughts <br />
            we’ve shared
          </p>
        </ArrowText>
      </Splash>
      <ContentHolder>
        <Container>
          <Holder>
            {data.allMarkdownRemark.edges.map((edge) => (
              <JournalCard key={edge.node.id} journalArticle={edge.node} />
            ))}
          </Holder>
          <Pagination pageContext={pageContext} />
        </Container>
        <CWReview />
      </ContentHolder>
    </>
  )
}

export default withTheme(JournalList)

export const journalListPageQuery = graphql`
  query journalListPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/_posts/journal/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date
            thumbnail
            link
            colour
          }
        }
      }
    }
  }
`
