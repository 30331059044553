import React, { Component } from 'react';
import showdown from 'showdown';
import { PropTypes } from 'prop-types';

const converter = new showdown.Converter({simpleLineBreaks: true});

class MarkdownConverter extends Component {
    render() {
        return (
          <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.content)}}/>
        )
    }
}

MarkdownConverter.propTypes = {
  content: PropTypes.string.isRequired,
};

export default MarkdownConverter;
