import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Holder = styled.div`
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
  a {
    text-decoration: none;
    font-weight: bold;
    &[aria-current="page"] {
      font-weight: normal;
    }
  }
`;

function Pagination( { pageContext } ) {

  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/journal/' : `/journal/${(currentPage - 1).toString()}`;
  const nextPage = `/journal/${(currentPage + 1).toString()}`;

  if( numPages <=1 ) return null;

  return (
    <Holder>
      <p>
        {!isFirst && (
          <Link to={prevPage} rel="prev">Newer</Link>
        )}
      </p>
      <ul>
        {Array.from( { length: numPages }, ( _, i ) => (
          <li key={`pagination-number${i + 1}`}>
            <Link to={`/journal/${i === 0 ? '' : i + 1}`}>
              {i + 1}
            </Link>
          </li>
        ) )}
      </ul>
      <p>
        {!isLast && (
          <Link to={nextPage} rel="next">Older</Link>
        )}
      </p>
    </Holder>
  )
}

export default Pagination;
