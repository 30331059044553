import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import Image from '../atoms/Image';

const Holder = styled.div`
  position: relative;
  padding-bottom: 3rem;
`;

const Bg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50%;
  background-color: ${props => props.theme.colours.transparentGrey2};
`;

const Text = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: calc(100% - 4rem);
  color: ${props => props.theme.colours.white};
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  @media( ${props => props.theme.breakpoints.md} ) { 
    width: 50%;
  }
  h3 {
    ${props => props.theme.fluidType( 10 )};
    margin: 0;
  }
`;

const ImageHolder = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 120%;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding-bottom: 50%;
  }
`;

const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

function CWReview() {
  return (
    <Holder>
      <Bg/>
      <Container>
        <a href="mailto:hello@craigwalker.com.au?subject=Can I please have a Craig Walker Review?">
          <ImageHolder>
            <ImageInner>
              <Image
                style={{
                  width: '100%',
                  height: '100%'
                }}
                imgStyle={{
                  objectPosition: 'center center',
                }}
                imgName="/cw-review-new.jpg"/>
            </ImageInner>
            <Text>
              <h3>Craig Walker <br/>Review</h3>
              <p><b>A collection of essays on the <br/>paradigm shifts in design</b></p>
            </Text>
          </ImageHolder>
        </a>
      </Container>
    </Holder>
  )
}

export default CWReview;
